import { ReactElement } from 'react'
import calendarIcon from 'assets/icons/calendar.svg'
import './Date.scss'

export const Number = ({ value = 0 }: { value: number }): ReactElement => {
  const result = String(value).padStart(2, '0')
  return (
    <div className="digital">
      <div>{result}</div>
    </div>
  )
}

export const Word = ({ value }: { value: string }): ReactElement => (
  <div className="digital">
    <div>{value}</div>
  </div>
)

export const DateComponent = (): ReactElement => {
  const now = new Date()

  return (
    <div className="h-100 d-flex justify-content-center align-items-center mx-3" id="date">
      <div className="d-flex justify-content-center calendar-wrapper">
        <img className="calendar-icon" src={calendarIcon} alt="calendar icon" height={18} />
        <Number value={now.getDate()} />
        <Word value="/" />
        <Number value={now.getMonth() + 1} />
        <Word value="/" />
        <Number value={now.getFullYear()} />
      </div>
    </div>
  )
}

export default DateComponent
