import { useTopBar } from 'components'
import {
  CATopBar, Countdown, Informations, MessageStream,
} from 'components/ca'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ThemisServices from 'services/ThemisServices'
import { RootState } from 'Store'
import { roles, usePageRole } from 'App'
import './CAPage.scss'

export default function CAPage() {
  usePageRole(roles.ca)
  useTopBar('Convergence d\'Aulnay', <CATopBar />)
  const dispatch = useDispatch()
  const { messages } = useSelector((state: RootState) => state.ca)

  useEffect(() => {
    const ct = (new Date()).getTime()
    dispatch(ThemisServices.getTime(ct))
  }, [])

  return (
    <div className="ca-page">
      <Informations messages={messages} />
      <Countdown />
      <MessageStream />
    </div>
  )
}
