/* eslint-disable camelcase */
import { signal } from '@preact/signals-react'
import { isDevEnv } from 'components/utils'
import { GATEWAY_DOMAIN } from 'config/config'
import {
  MutableRefObject, ReactElement, useEffect, useRef,
} from 'react'

type EventStreamState = {
  type: 'stream_state'
  observations: boolean
  'circulations-theoriques': boolean
}

export type Decision = {
  id: string
  date_hour: string
  train_number: string
  pr_libelle: string
  CI: number
  CH: string
  type_observation: 'A' | 'P' | 'D'
  gain: number
  score: number
  advised_track: '42' | '44' | null
  delay_train: number
  train_number_most_advanced_D?: string
  pr_libelle_most_advanced_D?: string
  CI_most_advanced_D?: number
  CH_most_advanced_D?: string
  type_observation_most_advanced_D?: 'A' | 'P' | 'D'
  delay_most_advanced_D?: number
}

type EventDecision = {
  type: 'decisions'
  decisions: Decision[]
}

type Event = EventStreamState | EventDecision

type WebSocketMessage = {
  type_message: 'events'
  timestamp: string
  messages: Event[]
}

type AlternatSteamState = {
  observations: boolean
  'circulations-theoriques': boolean
  decisions: Decision[]
}

export const AlternatStreamSignal = signal<AlternatSteamState>({
  observations: false,
  'circulations-theoriques': false,
  decisions: [],
})

export default function AlternatStream(): ReactElement {
  const ws: MutableRefObject<WebSocket> = useRef()
  const wsUrl = isDevEnv() ? 'alternat_42_44_mock' : 'alternat_42_44'
  useEffect(() => {
    ws.current = new WebSocket(
      `wss://${GATEWAY_DOMAIN.url}/alternat-42-44/${wsUrl}/?_auth_token=${`${localStorage.getItem(
        'access_token',
      )}`}`,
    )
    ws.current.onmessage = (event: MessageEvent) => {
      const wsMessage: WebSocketMessage = JSON.parse(event.data)
      if (!wsMessage.messages) return
      wsMessage.messages.forEach(message => {
        if (message.type === 'stream_state') {
          AlternatStreamSignal.value = {
            ...AlternatStreamSignal.value,
            observations: message.observations,
            'circulations-theoriques': message['circulations-theoriques'],
          }
        } else if (message.type === 'decisions') {
          AlternatStreamSignal.value = {
            ...AlternatStreamSignal.value,
            decisions: message.decisions,
          }
        }
      })
    }
    ws.current.onclose = () => {
      AlternatStreamSignal.value = {
        observations: false,
        'circulations-theoriques': false,
        decisions: [],
      }
    }

    ws.current.onerror = () => {
      AlternatStreamSignal.value = {
        observations: false,
        'circulations-theoriques': false,
        decisions: [],
      }
    }
    const wsCurrent = ws.current
    return () => {
      wsCurrent.close()
    }
  }, [])

  return (
    <> </>
  )
}
