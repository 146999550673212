import {
  AudioToggle, Clock, DateComponent, StatusStream,
} from 'components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { toggleActiveAudio } from 'reducers/ca'

export default function TopBar() {
  const dispatch = useDispatch()
  const { status, activeAudio } = useSelector((state: RootState) => state.ca)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleActiveAudio(event.target.checked))
  }

  return (
    <div style={{
      flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <StatusStream
        circulationsTheoriques={status.theoricalTrains}
        observations={status.observations}
      />
      <AudioToggle
        handleClick={handleChange}
        active={activeAudio}
      />
      <DateComponent />
      <Clock />
    </div>
  )
}
