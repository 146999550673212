import '@preact/signals-react/auto'
import {
  useEffect, Suspense, ReactElement, useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Router, Switch, Route, Redirect,
  useHistory,
} from 'react-router-dom'
import { auth } from '@osrdata/app_core'
import { RootState } from 'Store'
import history from 'customHistory'
import terms from 'terms.json'
import { AccessDenied, Loader, TopBar } from 'components'
import {
  PPNPage, CAPage, HomePage, AlternatPage,
} from 'pages'
import './App.scss'
import { useWakeLock } from 'react-screen-wake-lock'

export const routes = {
  home: '/',
  ppn: '/prio-paris-nord',
  ca: '/convergence-aulnay',
  alternat: '/alternat-42-44',
} as const

export const roles = {
  ppn: 'eris::PPN-ACCESS',
  ca: 'eris::CA-ACCESS',
  alternat: 'eris::ALTERNAT-ACCESS',
} as const

export const usePageRole = (role: typeof roles[keyof typeof roles]) => {
  const { appPermissions } = useSelector((state: RootState) => state.user)
  const historyContext = useHistory()
  useEffect(() => {
    if (!appPermissions.includes(role)) {
      historyContext.push(routes.home)
    }
  }, [])
}

export default function App(): ReactElement {
  const dispatch = useDispatch()
  const { released, request } = useWakeLock()
  const { isLogged, appPermissions } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(true)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
    request()
    const handleReactivateWakelock = () => {
      if (document.visibilityState === 'visible' && !released) {
        request()
      }
    }
    document.addEventListener('visibilitychange', handleReactivateWakelock)

    return () => {
      document.removeEventListener('visibilitychange', handleReactivateWakelock)
    }
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0) {
      if (!Object.values(roles).some(role => appPermissions.includes(role))) {
        setAccessDenied(true)
      }
      setIsLoading(false)
    }
  }, [isLogged, appPermissions])

  const renderApp = () => {
    if (accessDenied) return <AccessDenied />
    if (isLoading) return <Loader message={terms.Common.loading} standalone />

    return (
      <Switch>
        <Route exact component={HomePage} path={routes.home} />
        <Route exact component={PPNPage} path={routes.ppn} />
        <Route exact component={CAPage} path={routes.ca} />
        <Route exact component={AlternatPage} path={routes.alternat} />
        <Redirect to={routes.home} />
      </Switch>
    )
  }

  return (
    <>
      <Suspense fallback={<Loader message={terms.Common.loading} standalone />}>
        <Router history={history}>
          {!isLoading && (<TopBar hasAccess={!isLoading && !accessDenied} />)}
          <div id="app">{renderApp()}</div>
        </Router>
      </Suspense>
    </>
  )
}
