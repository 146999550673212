/* eslint-disable camelcase */
import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react'
import { isDevEnv } from 'components/utils'
import { GATEWAY_DOMAIN } from 'config/config'
import { PriorityCirculation } from 'types'
import './LeftPanel.scss'

type Event = {
  type: 'priority'
  circulations: PriorityCirculation[];
}

type WebSocketMessage = {
  type_message: 'events'
  timestamp: string
  messages: Event[]
}

export default function LeftPanel() {
  const ws: MutableRefObject<WebSocket> = useRef()
  const wsUrl = isDevEnv() ? 'prio_paris_nord_mock' : 'prio_paris_nord'
  const [priority, setPriority] = useState<PriorityCirculation[]>([])

  useEffect(() => {
    ws.current = new WebSocket(
      `wss://${GATEWAY_DOMAIN.url}/prio-paris-nord/${wsUrl}/?_auth_token=${`${localStorage.getItem(
        'access_token',
      )}`}`,
    )
    ws.current.onmessage = (event: MessageEvent) => {
      const wsMessage: WebSocketMessage = JSON.parse(event.data)
      if (!wsMessage.messages) return
      wsMessage.messages.forEach(message => {
        if (message.type === 'priority') {
          setPriority(message.circulations)
        }
      })
    }
    ws.current.onclose = () => {
      setPriority([])
    }

    ws.current.onerror = () => {
      setPriority([])
    }

    const wsCurrent = ws.current
    return () => {
      wsCurrent.close()
    }
  }, [])

  return (
    <div className="left-panel">
      <span className="title">
        Prévision ordonnancement RATP
      </span>
      <div className="circulation">
        {priority.length > 0 && (
          <>
            <span className="number">{priority[0].number}</span>
            <div className={`train-type type-${priority[0].train_type}`}>{priority[0].train_type}</div>
            <span>{`Voie ${priority[0].track}`}</span>
          </>
        )}
      </div>
      <div className="separator" />
      <div className="circulation">
        {priority.length > 1 && (
          <>
            <span className="number">{priority[1].number}</span>
            <span className={`train-type type-${priority[1].train_type}`}>{priority[1].train_type}</span>
            <span>{`Voie ${priority[1].track}`}</span>
          </>
        )}
      </div>
    </div>
  )
}
