import CircleIcon from '@mui/icons-material/Circle'
import terms from 'terms.json'
import './StatusStream.scss'

interface Props {
  observations: boolean
  circulationsTheoriques?: boolean
}

const StatusStream = ({ observations, circulationsTheoriques = undefined }: Props) => (
  <div className="status-stream">
    <div className="item">
      <span className="label">{terms.StatusStream.observations}</span>
      <CircleIcon className={`icon${observations ? ' success' : ''}`} />
    </div>
    {circulationsTheoriques !== undefined && (
      <div className="item">
        <span className="label">{terms.StatusStream.theoricalTrains}</span>
        <CircleIcon className={`icon${circulationsTheoriques ? ' success' : ''}`} />
      </div>
    )}
  </div>
)

StatusStream.defaultProps = {
  circulationsTheoriques: undefined,
}

export default StatusStream
