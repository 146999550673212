import { ReactComponent as InfosIcon } from 'assets/icons/infos.svg'
import { AlternatStreamSignal } from '../messageStream/MessageStream'
import DecisionItem from './DecisionItem'
import './DecisionsPanel.scss'

export default function DecisionsPanel() {
  return (
    <div className="decisions-panel">
      <span className="title">
        Information en temps réel
      </span>
      <div className="decisions">
        {AlternatStreamSignal.value.decisions.map(decision => (
          <DecisionItem key={decision.id} decision={decision} />
        ))}
      </div>
      <div className="footer">
        <InfosIcon />
        <span>
          Après modification de la programmation, aviser aiguilleur CLX, ADC et l’Info. voyageurs
        </span>
      </div>
    </div>
  )
}
