import { Clock, DateComponent, StatusStream } from 'components'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'

export default function TopBar() {
  const { streamState } = useSelector((state: RootState) => state.ppn)
  return (
    <div style={{
      flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <StatusStream
        observations={streamState.state}
      />
      <DateComponent />
      <Clock />
    </div>
  )
}
