import { Decision } from '../messageStream/MessageStream'
import './DecisionItem.scss'

interface Props {
  decision: Decision
}
const observationTypeLabel = {
  A: 'Arrivé à ',
  P: 'Passé à',
  D: 'Parti de',
}

const displayPreviousD = (decision: Decision) => {
  if (decision.train_number_most_advanced_D
    && decision.pr_libelle_most_advanced_D && decision.type_observation_most_advanced_D) {
    return (
      <div className="previous-d">
        <strong>{decision.train_number_most_advanced_D}</strong>
        <div className="train-type-D">D</div>
        <span>
          {`${
            observationTypeLabel[decision.type_observation_most_advanced_D]
          } ${decision.pr_libelle_most_advanced_D}`}
        </span>
      </div>
    )
  }
  return null
}

export default function DecisionItem({ decision }: Props) {
  return (
    <div className="decision">
      <div className="infos">
        <div className="train">
          <span>{decision.train_number}</span>
          <div className="train-type-B">B</div>
        </div>
        <span className="location">
          {`${observationTypeLabel[decision.type_observation]} ${decision.pr_libelle}`}
        </span>
      </div>
      <div className={`advised-track track${decision.advised_track}`}>
        {decision.advised_track && (
          <>
            <span className="advice">{`Recommandation : Voie ${decision.advised_track}`}</span>
            {displayPreviousD(decision)}
          </>
        )}
      </div>

    </div>
  )
}
