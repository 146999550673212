import { useTopBar } from 'components'
import { roles, usePageRole } from 'App'
import {
  AlternatStream, AlternatTopBar, DecisionsPanel, LeftPanel,
} from 'components/alternat'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ThemisServices from 'services/ThemisServices'
import './AlternatPage.scss'

export default function AlternatPage() {
  const dispatch = useDispatch()
  usePageRole(roles.alternat)
  useTopBar('Alternat 42-44', <AlternatTopBar />)

  useEffect(() => {
    const ct = (new Date()).getTime()
    dispatch(ThemisServices.getTime(ct))
  }, [])

  return (
    <div className="alternat-page">
      <LeftPanel />
      <DecisionsPanel />
      <AlternatStream />
    </div>
  )
}
