/* eslint-disable camelcase */
import { ReactElement, useEffect } from 'react'
import { TrackNumber } from 'types'

import terms from 'terms.json'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'Store'
import { PPNStreamState } from 'reducers/ppn'
import ThemisServices from 'services/ThemisServices'
import { Track, PPNStream, PPNTopBar } from 'components/ppn'
import { useTopBar } from 'components'
import { roles, usePageRole } from 'App'

export default function PPNPage(): ReactElement {
  usePageRole(roles.ppn)
  useTopBar('prio-paris-nord', <PPNTopBar />)
  const dispatch = useDispatch()
  const {
    track42, track44, priority,
  } = useSelector((state: RootState) => state.ppn) as PPNStreamState

  useEffect(() => {
    const ct = (new Date()).getTime()
    dispatch(ThemisServices.getTime(ct))
  }, [])

  return (
    <div className="d-flex w-100 h-100">
      <PPNStream />
      <div style={{ flex: 2 }} className="d-flex flex-column">
        <Track
          trackProps={track42}
          trackName={terms.Track.names[42]}
          hidePriority={priority.circulations[0]?.track !== TrackNumber.track42}
        />
        <div style={{ height: '14px', background: 'var(--color-carbone)' }} />
        <Track
          trackProps={track44}
          trackName={terms.Track.names[44]}
          hidePriority={priority.circulations[0]?.track !== TrackNumber.track44}
        />
      </div>
    </div>
  )
}
