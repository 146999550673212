/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ThemisServices from 'services/ThemisServices'
import {
  CirculationEvent, CirculationProps, CirculationType,
  EventType,
  FluxStateEvent, PriorityEvent,
  TrackNumber,
} from 'types'

export const DEFAULT_CIRCULATION: CirculationProps = {
  number: '',
  train_type: CirculationType.none,
  state: '',
}

export type PPNStreamState = {
  track42: CirculationProps[];
  track44: CirculationProps[];
  priority: PriorityEvent;
  streamState: FluxStateEvent;
  shouldRestart: boolean;
  clockSkew: number;
}

const initialState: PPNStreamState = {
  track42: [DEFAULT_CIRCULATION],
  track44: [DEFAULT_CIRCULATION],
  priority: {
    type: EventType.priority,
    circulations: [],
  },
  streamState: {
    type: EventType.stream_state,
    state: false,
  },
  shouldRestart: true,
  clockSkew: 0,
}

export const viewSlice = createSlice({
  name: 'ppn',
  initialState,
  reducers: {
    updatePriority: (state, action: PayloadAction<PriorityEvent>) => {
      state.priority = action.payload
    },
    updateFluxState: (state, action: PayloadAction<FluxStateEvent>) => {
      state.streamState = action.payload
    },
    updateCirculations: (state, action: PayloadAction<CirculationEvent>) => {
      if (String(action.payload.track) === TrackNumber.track42) {
        state.track42 = action.payload.circulations
      }
      if (String(action.payload.track) === TrackNumber.track44) {
        state.track44 = action.payload.circulations
      }
    },
    updateRestart: (state, action: PayloadAction<boolean>) => {
      state.shouldRestart = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(ThemisServices.getTime.fulfilled, (state, { payload }) => {
      const nowTimeStamp = (new Date()).getTime()

      const serverTimestamp = payload[0].server_time * 1000.0
      const clientTimestamp = payload[1]

      const clockSkew = ((serverTimestamp - clientTimestamp) + (serverTimestamp - nowTimeStamp)) / 2.0

      state.clockSkew = clockSkew
    })
  },
})

export const {
  updatePriority, updateCirculations, updateFluxState, updateRestart,
} = viewSlice.actions

export default viewSlice.reducer
