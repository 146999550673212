import { ArrowForwardIos } from '@mui/icons-material'
import { roles, routes } from 'App'
import { useTopBar } from 'components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from 'Store'
import terms from 'terms.json'
import './HomePage.scss'
import { useEffect } from 'react'

export default function HomePage() {
  useTopBar('ERIS')
  const history = useHistory()
  const { appPermissions } = useSelector((state: RootState) => state.user)
  const possibleRoles = Object.entries(roles).filter(([, role]) => appPermissions.includes(role))

  const handleClickRole = (role: string) => () => {
    const url = routes[role]
    if (url) {
      history.push(url)
    }
  }

  useEffect(() => {
    if (possibleRoles.length === 1) {
      const url = routes[possibleRoles[0][0]]
      if (url) history.push(url)
    }
  }, [])

  return (
    <div className="home-page">
      <h1 className="page-title">{terms.Home.title}</h1>
      <div className="role-selection">
        {possibleRoles.length === 0 ? (
          <span>{terms.Home.noRole}</span>
        ) : (
          <>
            <span>{terms.Home.chooseRole}</span>
            <div className="roles">
              {possibleRoles.map(([role]) => (
                <button
                  className="button-select"
                  onClick={handleClickRole(role)}
                  type="button"
                  key={role}
                >
                  <span>{terms.Common.roles[role]}</span>
                  <ArrowForwardIos />
                </button>
              ))}
            </div>
          </>
        )}

      </div>
    </div>
  )
}
