import getCSSValue from 'components/utils'
import { CirculationState, CirculationType, CirculationProps } from 'types'
import BTrainIcon from 'assets/icons/B_train.svg'
import DTrainIcon from 'assets/icons/D_train.svg'
import UnknownTrainIcon from 'assets/icons/unknown_train.svg'
import { DEFAULT_CIRCULATION } from 'reducers/ppn'

export const getWidth = (state: CirculationState | ''): string => {
  switch (state) {
    case CirculationState.approaching:
      return 'approaching'
    case CirculationState.onPlatform:
      return 'on-platform'
    case CirculationState.leaving:
      return 'leaving'
    default:
      return ''
  }
}

export const getBackgroundColor = (trackProp: CirculationProps | undefined): string => {
  const { state, train_type: type } = trackProp
  if (state === CirculationState.leaving || state === '') return getCSSValue('--color-grey-800')
  switch (type) {
    case CirculationType.B:
      return getCSSValue('--color-blue-600')
    case CirculationType.D:
      return getCSSValue('--color-green-500')
    default:
      return getCSSValue('--color-grey-800')
  }
}

export const getTrainIcon = (type: CirculationType): string | null => {
  switch (type) {
    case CirculationType.B:
      return BTrainIcon
    case CirculationType.D:
      return DTrainIcon
    default:
      return UnknownTrainIcon
  }
}

export const shouldDisplayByType = (type: CirculationType): boolean => (
  type === CirculationType.B || type === CirculationType.D
)
export const shouldDisplayByState = (state: CirculationState | ''): boolean => (
  state === CirculationState.approaching || state === CirculationState.onPlatform
)

// Find default type
export const getDefaultCirculation = (trackProps: CirculationProps[]): CirculationProps => {
  if (trackProps.length === 0) return DEFAULT_CIRCULATION
  if (trackProps.length > 1) {
    const onPlatformTrain = trackProps.find(p => p.state === CirculationState.onPlatform)
    if (onPlatformTrain !== undefined) return onPlatformTrain

    const arrivingTrain = trackProps.find(p => p.state === CirculationState.approaching)
    if (arrivingTrain !== undefined) return arrivingTrain

    return trackProps[0]
  }
  return trackProps[0]
}

// Should display circulation bar
// Either when 1 circulation,
// Or 2 circulations and selected circulation is on plateform
// Or 2 circulations, selected circulation is arriving and the other circulation is leaving
export const shouldDisplayCirculationBar = (
  state: CirculationState | '' | undefined, selectedTrackProps: CirculationProps, trackProps: CirculationProps[],
): boolean => trackProps.length === 1
    || state === CirculationState.onPlatform
    || (state === CirculationState.approaching
      && trackProps.filter(t => t.number !== selectedTrackProps.number)[0].state !== CirculationState.onPlatform)
