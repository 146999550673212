import BIcon from 'assets/icons/B.svg'
import DIcon from 'assets/icons/D.svg'
import unknownIcon from 'assets/icons/unknown.svg'
import { ReactElement } from 'react'
import { CirculationType, PriorityReason } from 'types'

import { RootState } from 'Store'
import getCSSValue from 'components/utils'
import { useSelector } from 'react-redux'
import { PPNStreamState } from 'reducers/ppn'
import './Priority.scss'

type Props = {
  hidden: boolean;
}

const getBackgroundColor = (type: CirculationType) => {
  switch (type) {
    case CirculationType.B:
      return getCSSValue('--color-blue-600')
    case CirculationType.D:
      return getCSSValue('--color-green-500')
    default:
      return getCSSValue('--color-grey-800')
  }
}

const getPriorityIcon = (type: CirculationType) => {
  switch (type) {
    case CirculationType.B:
      return BIcon
    case CirculationType.D:
      return DIcon
    default:
      return unknownIcon
  }
}

export default function Priority({ hidden }: Props): ReactElement {
  const { priority } = useSelector((state: RootState) => state.ppn as PPNStreamState)
  const style = hidden ? {
    flex: 0,
    height: 0,
  } : {
    flex: 1,
  }

  return (
    <>
      <div
        className="priority-panel d-flex position-relative"
        style={{ backgroundColor: getBackgroundColor(priority.circulations[0]?.train_type), ...style }}
      >
        <div
          className="text-container text-center"
          style={{ display: hidden ? 'none' : 'initial' }}
        >
          Priorité au

          <br />
          <img
            className="circulation"
            src={getPriorityIcon(priority.circulations[0]?.train_type)}
            alt="circulation logo"
          />

        </div>
        {priority.circulations[0]?.priority_reason && (
        <div className="reason">
          <div className="message">
            {PriorityReason[priority.circulations[0].priority_reason]}
          </div>

        </div>
        )}

      </div>

      {priority.circulations.length > 1 && !hidden && (
      <div className={`d-flex next-train type-${priority.circulations[1].train_type}`}>
        <div
          className={`circulation-type text-center type-${priority.circulations[1].train_type}`}
        >
          {priority.circulations[1].train_type === 'D' || priority.circulations[1].train_type === 'B'
            ? priority.circulations[1].train_type : ''}
        </div>
        <div className="info">
          <div className="next">
            Prochain train prioritaire
          </div>
          <div className="number">
            {priority.circulations[1].number}
          </div>

        </div>

      </div>
      )}
    </>
  )
}
