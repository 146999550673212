import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { GATEWAY_DOMAIN } from 'config/config'
import {
  resetMessages, setStatus, TrainInfoMessage, TrainInfoMessageNoId, updateMessages, WebSocketMessage,
} from 'reducers/ca'
import { isDevEnv } from 'components/utils'
import moment from 'moment'

const parseMessages = (messages: TrainInfoMessageNoId[]): TrainInfoMessage[] => messages.map(message => ({
  ...message,
  id: `${message.type_message}-${message.type_message === 'annonce'
    ? message.chrono_initial : message.numero_marche}-${message.heure_obs}`,
}))

const REFRESH_MSG = JSON.stringify({ type: 'refresh_update' })

const useTabActive = () => {
  const [visibilityState, setVisibilityState] = useState(true)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibilityState(document.visibilityState === 'visible')
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return visibilityState
}

const MessageStream = (): ReactElement => {
  const dispatch = useDispatch()
  const isTabActive = useTabActive()
  const ws = useRef<WebSocket>()
  const [statusTimestamp, setStatusTimestamp] = useState<Date>(new Date())

  useEffect(() => {
    const wsUrl = isDevEnv() ? 'convergence_aulnay_mock' : 'convergence_aulnay'
    const wsConnect = () => {
      ws.current = new WebSocket(`wss://${GATEWAY_DOMAIN.url}/convergence-aulnay/${wsUrl}/?_auth_token=${
        `${localStorage.getItem('access_token')}`}`)

      ws.current.onmessage = (event: { data: string }) => {
        const wsMessage: WebSocketMessage = JSON.parse(event.data)
        if (wsMessage.type_message === 'decisions') {
          dispatch(updateMessages(parseMessages(wsMessage.messages)))
        } else if (wsMessage.type_message === 'status') {
          dispatch(setStatus({
            observations: wsMessage.observations,
            theoricalTrains: wsMessage['circulations-theoriques'],
          }))
          setStatusTimestamp(new Date())
        }
      }

      // Try to reconnect after 1 second
      ws.current.onclose = () => {
        setTimeout(() => {
          wsConnect()
        }, 1000)
      }

      return ws.current
    }

    const wsCurrent = wsConnect()
    return () => {
      wsCurrent.close()
    }
  }, [])

  useEffect(() => {
    const wsCurrent = ws.current
    if (isTabActive && wsCurrent && wsCurrent.readyState === 1) {
      dispatch(resetMessages())
      wsCurrent.send(REFRESH_MSG)
    }
  }, [isTabActive])

  useEffect(() => {
    const interval = setInterval(() => {
      const duration = moment.duration(moment(new Date()).diff(moment(statusTimestamp)))
      if (duration.asSeconds() > 300) {
        dispatch(setStatus({ observations: false, theoricalTrains: false }))
      }
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [statusTimestamp])

  return (
    <></>
  )
}

export default MessageStream
