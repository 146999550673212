import { ChangeEvent } from 'react'
import './AudioToggle.scss'

interface Props {
  active: boolean
  handleClick: (event: ChangeEvent<HTMLInputElement>) => void
}

export default function AudioToggle({ active, handleClick }: Props) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="switch-audio-toggle">
      <input type="checkbox" checked={active} onChange={handleClick} />
      <span className="slider round" />
    </label>
  )
}
