import { ReactElement, ReactNode, useEffect } from 'react'
import { Signal } from '@preact/signals-react'
import { PORTAL_URL } from 'config/config'
import history from 'customHistory'
import scnfLogo from 'assets/logos/logo-sncf.svg'
import AccountMenu from './AccountMenu'
import './TopBar.scss'

type Props = {
  hasAccess: boolean;
}

const TopBarSignal = {
  appName: new Signal<string>(''),
  innerComponent: new Signal<ReactNode>(<div />),
}

export function useTopBar(appName: string, innerComponent: ReactNode = <div />): void {
  useEffect(() => {
    TopBarSignal.appName.value = appName
    TopBarSignal.innerComponent.value = innerComponent
  }, [appName, innerComponent])
}

export default function TopBar({ hasAccess }: Props): ReactElement {
  return (
    <div className="topbar d-flex align-items-center justify-content-between px-3">
      <header role="banner" className="d-none d-xl-flex">
        <div className="mastheader-logo">
          <a href={PORTAL_URL.url}>
            <img
              alt={TopBarSignal.appName.value}
              src={scnfLogo}
              width="70"
            />
          </a>
        </div>
        <div
          className="d-flex align-items-center"
          aria-hidden
          role="button"
          onClick={() => history.replace('/')}
        >
          <h1 className="text-uppercase pl-3 pt-1 mb-0 app-name">{TopBarSignal.appName.value}</h1>
        </div>
      </header>
      {hasAccess && TopBarSignal.innerComponent.value}
      <AccountMenu />
    </div>
  )
}
