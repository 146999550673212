import {
  AudioToggle, Clock, DateComponent, StatusStream,
} from 'components'
import gong from 'assets/sounds/gong.mp3'
import { useEffect, useState } from 'react'
import { AlternatStreamSignal } from '../messageStream/MessageStream'

export default function TopBar() {
  const [triggeredAlterts, setTriggeredAlerts] = useState<string[]>([])
  const [audio] = useState(new Audio(gong))
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    AlternatStreamSignal.value.decisions.forEach(decision => {
      if (!(decision.advised_track === '44')) return
      if (!checked) return
      if (triggeredAlterts.includes(decision.id)) return
      setTriggeredAlerts([...triggeredAlterts, decision.id])
      audio.currentTime = 0
      audio.play()
    })
  }, [AlternatStreamSignal.value.decisions])

  useEffect(() => {
    if (checked) audio.volume = 1
    else {
      audio.volume = 0
      audio.pause()
    }
  }, [checked])
  return (
    <div style={{
      flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
    }}
    >
      <StatusStream
        circulationsTheoriques={AlternatStreamSignal.value['circulations-theoriques']}
        observations={AlternatStreamSignal.value.observations}
      />
      <AudioToggle
        active={checked}
        handleClick={value => setChecked(value.target.checked)}
      />
      <DateComponent />
      <Clock />
    </div>
  )
}
